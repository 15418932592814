* {
  transition: all 0.6s cubic-bezier(0.75, 0, 0.175, 1);
}

@keyframes fadeOutUnmount {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    padding: 0;
    height: 0;
  }
}

body[data-aos-easing="custom-easing"] [data-aos],
[data-aos][data-aos][data-aos-easing="custom-easing"] {
  transition-timing-function: cubic-bezier(0.75, 0, 0.175, 1);
}

.MuiGrid-root img {
  max-width: 100%;
}

.highlight {
  font-weight: 500;
  font-size: 1.1rem;
}

/* lg */
@media (min-width: 1200px) {
  .MuiContainer-root {
    width: 90% !important;
    max-width: 1536px !important;
  }
}

